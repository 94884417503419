import { default as _91vanityUrl_93sImFc8NvE6Meta } from "/vercel/path0/src/pages/[vanityUrl].vue?macro=true";
import { default as detailsRCq8cZplFQMeta } from "/vercel/path0/src/pages/account/details.vue?macro=true";
import { default as reset_45passwordyquh6z3ZubMeta } from "/vercel/path0/src/pages/account/reset-password.vue?macro=true";
import { default as stripe3bUBqIImULMeta } from "/vercel/path0/src/pages/account/stripe.vue?macro=true";
import { default as impersonateqIDCoIZY4EMeta } from "/vercel/path0/src/pages/admin/impersonate.vue?macro=true";
import { default as index1kcBTKlDMxMeta } from "/vercel/path0/src/pages/admin/index.vue?macro=true";
import { default as idL8phQFAd2PMeta } from "/vercel/path0/src/pages/admin/transform/id.vue?macro=true";
import { default as cancelndB3qhl50wMeta } from "/vercel/path0/src/pages/cancel.vue?macro=true";
import { default as cancely96w3P97wmMeta } from "/vercel/path0/src/pages/event/[id]/cancel.vue?macro=true";
import { default as editN3SxsWw7SZMeta } from "/vercel/path0/src/pages/event/[id]/edit.vue?macro=true";
import { default as enterIN4uNXBossMeta } from "/vercel/path0/src/pages/event/[id]/enter.vue?macro=true";
import { default as indexbPy1tAptvTMeta } from "/vercel/path0/src/pages/event/[id]/index.vue?macro=true";
import { default as editAxD97QyVd1Meta } from "/vercel/path0/src/pages/event/[id]/show/[showId]/edit.vue?macro=true";
import { default as createJ5jEiGMJVmMeta } from "/vercel/path0/src/pages/event/[id]/show/create.vue?macro=true";
import { default as successRYv39GtIPyMeta } from "/vercel/path0/src/pages/event/[id]/success.vue?macro=true";
import { default as viewGfmaOOFwuiMeta } from "/vercel/path0/src/pages/event/[id]/view.vue?macro=true";
import { default as createfAZ4rOjoexMeta } from "/vercel/path0/src/pages/event/create.vue?macro=true";
import { default as indexkP7wmsbr11Meta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as eventsXDLPfsOYF9Meta } from "/vercel/path0/src/pages/my/events.vue?macro=true";
import { default as exhibitorsuO6ZWPDbOGMeta } from "/vercel/path0/src/pages/my/exhibitors.vue?macro=true";
import { default as historyNnlsQMjhpDMeta } from "/vercel/path0/src/pages/my/payment/history.vue?macro=true";
import { default as not_45foundJWmWwVwBmVMeta } from "/vercel/path0/src/pages/not-found.vue?macro=true";
import { default as privacy_45policyWSAyrgHMl5Meta } from "/vercel/path0/src/pages/privacy-policy.vue?macro=true";
import { default as recoveryOGIg0QtP4LMeta } from "/vercel/path0/src/pages/recovery.vue?macro=true";
import { default as release_45logUJOwsmnX9BMeta } from "/vercel/path0/src/pages/release-log.vue?macro=true";
import { default as sign_45ingsNb1nKdl2Meta } from "/vercel/path0/src/pages/sign-in.vue?macro=true";
import { default as sign_45upXYyXCgonEKMeta } from "/vercel/path0/src/pages/sign-up.vue?macro=true";
import { default as successyBvUHTAGOWMeta } from "/vercel/path0/src/pages/success.vue?macro=true";
import { default as termswXmZiiNRxwMeta } from "/vercel/path0/src/pages/terms.vue?macro=true";
export default [
  {
    name: "vanityUrl",
    path: "/:vanityUrl()",
    component: () => import("/vercel/path0/src/pages/[vanityUrl].vue").then(m => m.default || m)
  },
  {
    name: "account-details",
    path: "/account/details",
    meta: detailsRCq8cZplFQMeta || {},
    component: () => import("/vercel/path0/src/pages/account/details.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password",
    path: "/account/reset-password",
    meta: reset_45passwordyquh6z3ZubMeta || {},
    component: () => import("/vercel/path0/src/pages/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "account-stripe",
    path: "/account/stripe",
    meta: stripe3bUBqIImULMeta || {},
    component: () => import("/vercel/path0/src/pages/account/stripe.vue").then(m => m.default || m)
  },
  {
    name: "admin-impersonate",
    path: "/admin/impersonate",
    meta: impersonateqIDCoIZY4EMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/impersonate.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: index1kcBTKlDMxMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-transform-id",
    path: "/admin/transform/id",
    meta: idL8phQFAd2PMeta || {},
    component: () => import("/vercel/path0/src/pages/admin/transform/id.vue").then(m => m.default || m)
  },
  {
    name: "cancel",
    path: "/cancel",
    component: () => import("/vercel/path0/src/pages/cancel.vue").then(m => m.default || m)
  },
  {
    name: "event-id-cancel",
    path: "/event/:id()/cancel",
    component: () => import("/vercel/path0/src/pages/event/[id]/cancel.vue").then(m => m.default || m)
  },
  {
    name: "event-id-edit",
    path: "/event/:id()/edit",
    meta: editN3SxsWw7SZMeta || {},
    component: () => import("/vercel/path0/src/pages/event/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "event-id-enter",
    path: "/event/:id()/enter",
    meta: enterIN4uNXBossMeta || {},
    component: () => import("/vercel/path0/src/pages/event/[id]/enter.vue").then(m => m.default || m)
  },
  {
    name: "event-id",
    path: "/event/:id()",
    component: () => import("/vercel/path0/src/pages/event/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "event-id-show-showId-edit",
    path: "/event/:id()/show/:showId()/edit",
    meta: editAxD97QyVd1Meta || {},
    component: () => import("/vercel/path0/src/pages/event/[id]/show/[showId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "event-id-show-create",
    path: "/event/:id()/show/create",
    meta: createJ5jEiGMJVmMeta || {},
    component: () => import("/vercel/path0/src/pages/event/[id]/show/create.vue").then(m => m.default || m)
  },
  {
    name: "event-id-success",
    path: "/event/:id()/success",
    component: () => import("/vercel/path0/src/pages/event/[id]/success.vue").then(m => m.default || m)
  },
  {
    name: "event-id-view",
    path: "/event/:id()/view",
    meta: viewGfmaOOFwuiMeta || {},
    component: () => import("/vercel/path0/src/pages/event/[id]/view.vue").then(m => m.default || m)
  },
  {
    name: "event-create",
    path: "/event/create",
    meta: createfAZ4rOjoexMeta || {},
    component: () => import("/vercel/path0/src/pages/event/create.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "my-events",
    path: "/my/events",
    meta: eventsXDLPfsOYF9Meta || {},
    component: () => import("/vercel/path0/src/pages/my/events.vue").then(m => m.default || m)
  },
  {
    name: "my-exhibitors",
    path: "/my/exhibitors",
    meta: exhibitorsuO6ZWPDbOGMeta || {},
    component: () => import("/vercel/path0/src/pages/my/exhibitors.vue").then(m => m.default || m)
  },
  {
    name: "my-payment-history",
    path: "/my/payment/history",
    meta: historyNnlsQMjhpDMeta || {},
    component: () => import("/vercel/path0/src/pages/my/payment/history.vue").then(m => m.default || m)
  },
  {
    name: "not-found",
    path: "/not-found",
    component: () => import("/vercel/path0/src/pages/not-found.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/vercel/path0/src/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "recovery",
    path: "/recovery",
    meta: recoveryOGIg0QtP4LMeta || {},
    component: () => import("/vercel/path0/src/pages/recovery.vue").then(m => m.default || m)
  },
  {
    name: "release-log",
    path: "/release-log",
    component: () => import("/vercel/path0/src/pages/release-log.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45ingsNb1nKdl2Meta || {},
    component: () => import("/vercel/path0/src/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upXYyXCgonEKMeta || {},
    component: () => import("/vercel/path0/src/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "success",
    path: "/success",
    component: () => import("/vercel/path0/src/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/vercel/path0/src/pages/terms.vue").then(m => m.default || m)
  }
]