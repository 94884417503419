/**
 * Retrieve the database maximum value for an `int4` column.
 *
 * @returns The number `2147483647`.
 */
export function useInt4Max() {
  return 2147483647
}

/**
 * Check against the database maximum value for an `int4` column.
 *
 * @param value - A number to check if it is equal to `2147483647`.
 * @returns A boolean if the value is equal to `2147483647`.
 */
export function useIsInt4Max(value: number) {
  return value === 2147483647
}
