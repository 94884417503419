export const useApp = defineStore('app', () => {
  const colorMode = useColorMode()
  const state = reactive({
    isLoading: true,
    isDark: computed({
      get: () => colorMode.value === 'dark',
      set() {
        colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
      },
    }),
  })
  const layout = reactive({
    isSlideoverCartOpen: false,
    isModalContactUsOpen: false,
  })
  const randomTitle = computed(
    () => ['Howdy', 'Welcome', 'Hi again', 'Hey there'][Math.floor(Math.random() * 4)],
  )
  const data = reactive({
    title: '',
    time: useTime(),
    loadingRedirectStatus: null as string | null,
  })

  if (import.meta.client) {
    useIntervalFn(() => {
      data.time = useTime()
    }, 30000)
  }

  return {
    state,
    layout,
    data: {
      ...data,
      randomTitle,
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApp, import.meta.hot))
}
