<template>
  <main
    data-testid="main"
    class="fixed inset-0 overflow-hidden"
  >
    <loading-redirect
      v-if="typeof app.data.loadingRedirectStatus === 'string'"
      :status="app.data.loadingRedirectStatus"
    />
    <template v-else>
      <nuxt-layout>
        <nuxt-page />
      </nuxt-layout>
      <u-notifications />
      <u-modals />
      <u-slideovers />
      <modal-contact-us v-model="app.layout.isModalContactUsOpen" />
      <popup-cookies />
    </template>
  </main>
  <p style="display: none">
    © 2023 The Show Portal LLC. All rights reserved.
  </p>
</template>

<script lang="ts" setup>
useSchemaOrg([
  defineOrganization({
    name: 'The Show Portal',
    logo: '/logo.png',
    sameAs: ['https://theshowportal.com'],
  }),
  defineWebSite({
    name: 'The Show Portal',
  }),
  defineWebPage({
    canonicalHost: 'https://app.theshowportal.com',
  }),
])

useSeoMeta({
  description: 'An all-in-one event management platform for livestock events.',
  ogTitle: 'The Show Portal',
  ogDescription: 'An all-in-one event management platform for livestock events.',
  ogImage: {
    type: 'image/png',
    url: 'https://app.theshowportal.com/icon-512x512.png',
    width: 512,
    height: 512,
  },
  ogUrl: 'https://app.theshowportal.com',
  twitterTitle: 'The Show Portal',
  twitterDescription: 'An all-in-one event management platform for livestock events.',
  twitterImage: {
    type: 'image/png',
    url: 'https://app.theshowportal.com/icon-512x512.png',
    width: 512,
    height: 512,
  },
  twitterCard: 'summary',
})

useHead({
  htmlAttrs: {
    lang: 'en',
  },
  link: [
    {
      rel: 'manifest',
      href: '/site.webmanifest',
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/android-chrome-192x192.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png',
    },
  ],
})

const route = useRoute()
const supabase = useSupabaseClient<Supabase>()
const app = useApp()
const toast = useToast()

if (route.query.type === 'magiclink' && typeof route.query.token_hash === 'string') {
  const { error } = await supabase.auth.verifyOtp({
    token_hash: route.query.token_hash,
    type: 'magiclink',
  })

  if (error) {
    toast.add({
      color: 'red',
      title: 'Could not verify magic link.',
      description: 'Please try again later.',
    })
  }

  await navigateTo('/')
}
</script>

<style lang="scss">
:root {
  --vc-gray-50: rgb(var(--color-gray-50));
  --vc-gray-100: rgb(var(--color-gray-100));
  --vc-gray-200: rgb(var(--color-gray-200));
  --vc-gray-300: rgb(var(--color-gray-300));
  --vc-gray-400: rgb(var(--color-gray-400));
  --vc-gray-500: rgb(var(--color-gray-500));
  --vc-gray-600: rgb(var(--color-gray-600));
  --vc-gray-700: rgb(var(--color-gray-700));
  --vc-gray-800: rgb(var(--color-gray-800));
  --vc-gray-900: rgb(var(--color-gray-900));
}

.vc-primary {
  --vc-accent-50: rgb(var(--color-primary-50));
  --vc-accent-100: rgb(var(--color-primary-100));
  --vc-accent-200: rgb(var(--color-primary-200));
  --vc-accent-300: rgb(var(--color-primary-300));
  --vc-accent-400: rgb(var(--color-primary-400));
  --vc-accent-500: rgb(var(--color-primary-500));
  --vc-accent-600: rgb(var(--color-primary-600));
  --vc-accent-700: rgb(var(--color-primary-700));
  --vc-accent-800: rgb(var(--color-primary-800));
  --vc-accent-900: rgb(var(--color-primary-900));
}

.dark {
  .vc-primary {
    --vc-accent-50: rgb(var(--color-gray-50));
    --vc-accent-100: rgb(var(--color-gray-100));
    --vc-accent-200: rgb(var(--color-gray-200));
    --vc-accent-300: rgb(var(--color-gray-300));
    --vc-accent-400: rgb(var(--color-gray-400));
    --vc-accent-500: rgb(var(--color-gray-500));
    --vc-accent-600: rgb(var(--color-gray-600));
    --vc-accent-700: rgb(var(--color-gray-700));
    --vc-accent-800: rgb(var(--color-gray-800));
    --vc-accent-900: rgb(var(--color-gray-900));
  }
}

html,
body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  width: 100vw;
  height: 100%;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

h1 {
  font-size: 2.8rem;
  margin: 0;
  font-weight: 500;
}

h2 {
  font-size: 2.75rem;
  margin: 0;
  font-weight: 500;
}

h3 {
  font-size: 2.5rem;
  margin: 0;
  font-weight: 500;
}

h4 {
  font-size: 2rem;
  margin: 0;
  font-weight: 500;
}

h5 {
  font-size: 1.5rem;
  margin: 0;
  font-weight: 500;
}

h6 {
  font-size: 1.15rem;
  margin: 0;
  font-weight: 400;
}

p {
  margin: 0;
}
</style>
