export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('mask', {
    mounted(el, binding) {
      const format = binding.arg || 'date'
      const inputElement = el.tagName === 'INPUT' ? el : el.querySelector('input')

      inputElement.setAttribute(
        'placeholder',
        format === 'date' ? 'MM/DD/YYYY' : '(___) ___-____',
      )

      function handleInput(e: InputEvent) {
        let value = (e.target as HTMLInputElement)?.value.replace(/\D+/g, '')

        if (format === 'date') {
          if (value.length > 2) {
            value = value.slice(0, 2) + '/' + value.slice(2)
          }
          if (value.length > 5) {
            value = value.slice(0, 5) + '/' + value.slice(5)
          }
          if (value.length > 10) {
            value = value.slice(0, 10)
          }
        }
        else if (format === 'phone') {
          if (value.length > 3) {
            value = '(' + value.slice(0, 3) + ') ' + value.slice(3)
          }
          if (value.length > 9) {
            value = value.slice(0, 9) + '-' + value.slice(9)
          }
          if (value.length > 14) {
            value = value.slice(0, 14)
          }
        }

        if ((e.target as HTMLInputElement)?.value !== value) {
          ;(e.target as HTMLInputElement).value = value
          ;(e.target as HTMLInputElement).dispatchEvent(new Event('input'))
        }
      }

      useEventListener(inputElement, 'input', handleInput)
    },
  })
})
