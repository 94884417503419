export default defineNuxtPlugin(() => {
  const supabase = useSupabaseClient<Supabase>()
  const data = useData()
  const tables = new Set([
    'admins',
    'events',
    'exhibitors',
    'extras',
    'feed',
    'payments',
    'payments_exhibitors',
    'payments_show_objects',
    'payments_extras',
    'scope_requests',
    'show_objects',
    'shows',
    'subusers',
  ])

  supabase
    .channel('changes')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
      },
      async (payload) => {
        const auth = useAuth()

        // TODO: update events_entry_count
        try {
          if (payload.table === 'users') {
            const auth = useAuth()

            if (payload.eventType === 'DELETE' && auth.userId === payload.old.id) {
              supabase.auth.signOut()
            }
            else if ((payload.new as PortalUser).id === auth.userId) {
              auth.user = payload.new as PortalUser
            }
          }
          else if (payload.table === 'scopes') {
            if (
              (payload.eventType === 'INSERT' || payload.eventType === 'UPDATE')
              && (payload.new.scoped_user_id === auth.userId
              || payload.new.user_id === auth.userId)
            ) {
              data.scopes.set(payload.new.resource_id, payload.new as PortalScope)
            }
            else {
              data.scopes.delete((payload.old as PortalScope).resource_id)
            }
          }
          else if (tables.has(payload.table)) {
            if (payload.eventType === 'DELETE') {
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              ;(data as any)[payload.table].delete(payload.old.id)
            }
            else {
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              ;(data as any)[payload.table].set(payload.new.id, payload.new)
            }
          }
        }
        catch (error) {
          console.error(error)
        }
      },
    )
    .subscribe()
})
