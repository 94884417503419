import { Loader } from '@googlemaps/js-api-loader'

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig()
  const loader = new Loader({
    apiKey: config.public.googleApiKey,
    version: 'weekly',
    libraries: ['places'],
  })
  const { Map } = await loader.importLibrary('maps')
  const { Marker } = await loader.importLibrary('marker')

  return {
    provide: {
      google: {
        Map,
        Marker,
      },
    },
  }
})
