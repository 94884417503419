export default defineNuxtPlugin((nuxt) => {
  const app = useApp()

  nuxt.hook('page:loading:start', () => {
    app.state.isLoading = true
    app.layout.isModalContactUsOpen = false
    app.layout.isSlideoverCartOpen = false
  })

  nuxt.hook('page:loading:end', () => {
    app.state.isLoading = false
  })
})
