export const appHead = {"meta":[{"name":"description","content":"An all-in-one event management platform for livestock events."},{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"http-equiv":"Content-Security-Policy","content":"default-src 'self'; script-src 'self' 'unsafe-inline' https://*.googleapis.com https://vercel.live; style-src 'self' 'unsafe-inline' https://*.googleapis.com https://vercel.live; font-src 'self' https://*.gstatic.com https://vercel.live data:; frame-src 'self' https://vercel.live; img-src 'self' https://udsbfhxihpwyylvjqrna.supabase.co https://*.ggpht.com https://*.googleapis.com https://*.gstatic.com https://vercel.com data:; connect-src 'self' https://udsbfhxihpwyylvjqrna.supabase.co wss://udsbfhxihpwyylvjqrna.supabase.co https://*.googleapis.com https://vercel.live https://o4506609859887104.ingest.sentry.io https://*.pusher.com ws://localhost:4000 wss://localhost:4000 wss://*.pusher.com; worker-src 'self' blob:"}],"link":[],"style":[],"script":[],"noscript":[],"title":"The Show Portal"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000