import type Stripe from 'stripe'
import type { Session } from '@supabase/supabase-js'

export const useAuth = defineStore('auth', () => {
  const supabase = useSupabaseClient<Supabase>()
  const app = useApp()
  const data = useData()
  const accessToken = useCookie('the-show-portal-access-token', {
    path: '/',
    sameSite: 'lax',
    secure: true,
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10),
  })
  const refreshToken = useCookie('the-show-portal-refresh-token', {
    path: '/',
    sameSite: 'lax',
    secure: true,
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10),
  })
  const userSession = ref<Session | null | undefined>(undefined)
  const userId = ref<string>('')
  const {
    pause: stopGrabUser,
    resume: resumeGrabUser,
    isActive: isGrabbingUser,
  } = useTimeoutPoll(
    async () => {
      if (user.value?.id === userId.value && isGrabbingUser.value) {
        stopGrabUser()

        return
      }

      if (userSession.value && userId.value.length) {
        const { data: retrieved } = await supabase
          .from('users')
          .select()
          .eq('id', userId.value)
          .maybeSingle<PortalUser>()

        if (retrieved) {
          user.value = retrieved

          stopGrabUser()
        }
      }
    },
    500,
    {
      immediate: false,
    },
  )
  const {
    pause: stopGrabStripeAccount,
    resume: resumeGrabStripeAccount,
    isActive: isGrabbingStripeAccount,
  } = useTimeoutPoll(
    async () => {
      if (stripeAccount.value && isStripeVerified.value) {
        stopGrabStripeAccount()

        return
      }

      const { data: stripeAccountData } = (await $fetch('/api/v1/stripe/account')) as {
        data: Stripe.Account
        error?: Error
      }

      if (stripeAccountData) {
        stripeAccount.value = stripeAccountData
      }
    },
    10000,
    {
      immediate: false,
    },
  )
  const user = ref<PortalUser | undefined>()
  const userCart = computed<PortalCart>(() => user.value?.metadata.cart ?? [])
  const userCartShowObjects = ref<Map<string, ObjectInCart>>(new Map())
  const userCartExtras = ref<Map<string, ExtraInCart>>(new Map())
  const stripeAccount = ref<Stripe.Account | undefined>()
  const isAdmin = computedAsync<boolean>(async () => {
    if (userSession.value) {
      const existingAdmin = data.admins.get(userSession.value.user.id)

      if (existingAdmin) {
        return true
      }

      const { data: retrievedAdmin } = await supabase
        .from('admins')
        .select()
        .eq('user_id', userSession.value.user.id)
        .maybeSingle<PortalAdmin>()

      if (retrievedAdmin) {
        data.admins.set(retrievedAdmin.id, retrievedAdmin)

        return true
      }
    }

    return false
  })
  const isSelf = computed<boolean>(() =>
    Boolean(userSession.value && userSession.value.user.id === userId.value),
  )
  const isStripeVerified = computed<boolean>(() =>
    Boolean(
      stripeAccount.value
      && stripeAccount.value.details_submitted
      && stripeAccount.value.charges_enabled
      && stripeAccount.value.payouts_enabled
      && stripeAccount.value.capabilities?.transfers === 'active'
      && stripeAccount.value.capabilities?.card_payments === 'active',
    ),
  )
  const debouncedComputeCart = useDebounceFn(computeCart, 150, { maxWait: 500 })

  async function impersonate(otherUserId: string) {
    if (isAdmin.value) {
      userId.value = otherUserId

      await navigateTo('/')
    }
    else {
      throw new Error('Unauthorized')
    }
  }

  function computeEntryFee(show: PortalShow, numberEntriesRequested: number) {
    let computedFee = 0
    let computedDiscount = 1

    for (const entryFee of show.entry_fees) {
      if (entryFee[0] === null || app.data.time.isAfter(entryFee[0])) {
        computedFee = entryFee[1]
      }
    }

    for (const discount of show.entry_discounts) {
      if (discount[0] <= numberEntriesRequested && discount[1] < computedDiscount) {
        computedDiscount = Math.max(0, Math.min(1, discount[1]))
      }
    }

    return Math.max(0, computedFee * computedDiscount)
  }

  function computeExtraPrice(extra: PortalExtra, item: PortalCartExtra) {
    let computedPrice = extra.price

    for (const group in item.variants_requested) {
      const variant = Object.values(extra.metadata.variants ?? {}).find(
        (v) => v.group === group && v.name === item.variants_requested[group],
      )

      computedPrice += variant?.diff ?? 0
    }

    return Math.max(0, computedPrice)
  }

  async function removeItem(id: string) {
    try {
      app.state.isLoading = true

      if (!user.value) {
        throw new Error('User not found')
      }

      const newCart = userCart.value.filter((item) => item.id !== id)

      if (userCart.value.length === newCart.length) {
        return
      }

      const { error } = await supabase
        .from('users')
        .update({
          metadata: {
            ...user.value.metadata,
            cart: newCart,
          },
        })
        .eq('id', userId.value)

      if (error) {
        throw error
      }

      if (!newCart.length) {
        app.layout.isSlideoverCartOpen = false
      }
    }
    catch (error) {
      console.error(error)
    }
    finally {
      app.state.isLoading = false
    }
  }

  async function computeCart(cart: typeof userCart.value) {
    if (!cart.length) {
      userCartShowObjects.value.clear()
      userCartExtras.value.clear()
      return
    }

    for (const [id] of userCartShowObjects.value) {
      if (!cart.find((i) => i.id === id)) {
        userCartShowObjects.value.delete(id)
      }
    }

    for (const [id] of userCartExtras.value) {
      if (!cart.find((i) => i.id === id)) {
        userCartExtras.value.delete(id)
      }
    }

    const eventIds = cart
      .filter(({ event_id }) => !data.events.has(event_id))
      .map(({ event_id }) => event_id)

    if (eventIds.length) {
      const { data: retrievedEvents } = await supabase
        .from('events')
        .select()
        .in('id', eventIds)
        .returns<PortalEvent[]>()

      data.cacheMany('events', retrievedEvents)
    }

    const showIds = (
      cart.filter(
        (item) => item.type === 'show_object' && !data.shows.has(item.show_id),
      ) as PortalCartShowObject[]
    ).map(({ show_id }) => show_id)

    if (showIds.length) {
      const { data: retrievedShows } = await supabase
        .from('shows')
        .select()
        .in('id', showIds)
        .returns<PortalShow[]>()

      data.cacheMany('shows', retrievedShows)
    }

    const subuserIds = cart
      .filter(({ subuser_id }) => !data.subusers.has(subuser_id))
      .map(({ subuser_id }) => subuser_id)

    if (subuserIds.length) {
      const { data: retrievedSubusers } = await supabase
        .from('subusers')
        .select()
        .in('id', subuserIds)
        .returns<PortalSubuser[]>()

      data.cacheMany('subusers', retrievedSubusers)
    }

    const extraIds = (
      cart.filter(
        (item) => item.type === 'extra' && !data.extras.has(item.id.split('.')[0]),
      ) as PortalCartExtra[]
    ).map(({ id }) => id.split('.')[0])

    if (extraIds.length) {
      const { data: retrievedExtras } = await supabase
        .from('extras')
        .select()
        .in('id', extraIds)
        .returns<PortalExtra[]>()

      data.cacheMany('extras', retrievedExtras)
    }

    for (const item of cart) {
      if (item.type === 'show_object') {
        const event = data.events.get(item.event_id)
        const show = data.shows.get(item.show_id)
        const subuser = data.subusers.get(item.subuser_id)

        if (!(event && show && subuser)) {
          await removeItem(item.id)
          continue
        }

        const numberEntriesRequested = userCart.value.filter(
          (i) =>
            i.type === 'show_object'
            && i.show_id === show.id
            && i.subuser_id === subuser.id,
        ).length

        const limits = await useShowEntryLimits(show.id, subuser.id)

        userCartShowObjects.value.set(item.id, {
          ...event,
          // TODO: Fix this
          specie: '',
          is_entries_per_specie_full: limits.is_entries_per_specie_full,
          is_entries_per_specie_per_exhibitor_full:
            limits.is_entries_per_specie_per_exhibitor_full,
          show: {
            ...show,
            computed_price: computeEntryFee(show, numberEntriesRequested),
            is_entries_full: limits.is_entries_full,
            is_entries_per_exhibitor_full: limits.is_entries_per_exhibitor_full,
            show_entries_current: limits.total_subuser_entries,
            show_entries_requested: numberEntriesRequested,
            total_entries: limits.total_entries,
          },
          subuser,
          item,
        })
      }
      else if (item.type === 'extra') {
        if (item.quantity_requested <= 0) {
          await removeItem(item.id)
          continue
        }

        const extraId = item.id.split('.')[0]
        const event = data.events.get(item.event_id)
        const extra = data.extras.get(extraId)
        const subuser = data.subusers.get(item.subuser_id)

        if (!(event && extra && subuser)) {
          await removeItem(item.id)
          continue
        }

        const stock = await $fetch('/api/v1/extras/quantity-remaining', {
          params: {
            event_id: useShortId(item.event_id),
          },
        })
        let quantityRemaining = Infinity

        if ('data' in stock) {
          for (const extraId in stock.data ?? {}) {
            if (extraId === extra.id) {
              let total = 0

              for (const group in stock.data[extraId] ?? {}) {
                if (group === 'no_variant') {
                  total += stock.data[extraId][group]['default']
                  continue
                }

                for (const variant in stock.data[extraId][group] ?? {}) {
                  const extraVariant = Object.values(extra.metadata.variants ?? {}).find(
                    (v) =>
                      (v as PortalExtraVariant).group === group
                      && (v as PortalExtraVariant).name === variant,
                  )

                  if (extraVariant?.qty) {
                    const remaining
                      = extraVariant.qty - stock.data[extraId][group][variant]

                    if (remaining < quantityRemaining) {
                      quantityRemaining = remaining
                    }
                  }

                  total += stock.data[extraId][group][variant]
                }
              }

              if (!useIsInt4Max(extra.quantity)) {
                const remaining = extra.quantity - total

                if (remaining < quantityRemaining) {
                  quantityRemaining = remaining
                }
              }
            }
          }
        }
        userCartExtras.value.set(item.id, {
          ...event,
          subuser,
          extra: {
            ...extra,
            computed_price: computeExtraPrice(extra, item),
          },
          item,
          quantity_remaining: quantityRemaining,
        })
      }
      else {
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        await removeItem((item as any).id)
      }
    }
  }

  watch(userId, async (value) => {
    if (!value.length) {
      // Likely, the user signed out
      stopGrabStripeAccount()
      stopGrabUser()

      stripeAccount.value = undefined
      user.value = undefined
      data.clearAll()

      accessToken.value = null
      refreshToken.value = null

      await navigateTo('/')

      return
    }

    if (userSession.value) {
      if (value !== userSession.value.user.id && !isAdmin.value) {
        userId.value = userSession.value.user.id
      }

      if (!isGrabbingUser.value) {
        resumeGrabUser()
      }
    }
    else {
      userId.value = ''
    }
  })

  watch(
    user,
    async (value) => {
      if (!value || !value.stripe_connect_id) {
        return
      }

      if (
        value.stripe_connect_id
        && !isGrabbingStripeAccount.value
        && (!stripeAccount.value || !isStripeVerified.value)
      ) {
        resumeGrabStripeAccount()
      }

      await debouncedComputeCart(userCart.value)
    },
    { immediate: true, deep: true },
  )

  watch(userCart, debouncedComputeCart, { deep: true })

  watch(
    [user, () => data.events, () => data.shows, () => data.subusers, () => data.extras],
    async () => {
      await debouncedComputeCart(userCart.value)
    },
    { deep: true },
  )

  const unsubscribe = watch(isStripeVerified, (value) => {
    if (value && isGrabbingStripeAccount.value) {
      stopGrabStripeAccount()

      unsubscribe()
    }
  })

  if (import.meta.client) {
    supabase.auth.onAuthStateChange((event, session) => {
      accessToken.value = session?.access_token ?? null
      refreshToken.value = session?.refresh_token ?? null
      userSession.value = session

      if (!session) {
        userId.value = ''
        return
      }

      if (session && !userId.value.length) {
        userId.value = session.user.id
      }

      if (event === 'PASSWORD_RECOVERY') {
        navigateTo('/account/reset-password')

        return
      }
    })

    try {
      if (
        !userSession.value
        && typeof accessToken.value === 'string'
        && typeof refreshToken.value === 'string'
      ) {
        supabase.auth.setSession({
          access_token: accessToken.value,
          refresh_token: refreshToken.value,
        })
      }
    }
    catch (error) {
      //
    }
  }
  return {
    session: userSession,
    userId,
    user,
    userCart,
    userCartShowObjects,
    userCartExtras,
    stripeAccount,
    isAdmin,
    isSelf,
    isStripeVerified,
    impersonate,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuth, import.meta.hot))
}
