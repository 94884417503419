<template>
  <div
    v-if="showAgree"
    :class="{
      'fixed': true,
      'z-[10005]': true,
      'bottom-16': true,
      'left-4': true,
      'right-4': true,
      'p-8': true,
      'pb-6': true,
      'bg-tspblue-100 dark:bg-tspblue-500': true,
      'shadow-xl': true,
      'rounded-md': true,
      'box-border': true,
      'md:w-[50vw]': true,
      'md:max-w-screen-sm': true,
      'md:left-1/2': true,
      'md:-translate-x-1/2': true,
    }"
  >
    <p>
      This site uses cookies to allow for user access management and provide other
      convenience features to you. Please read our
      <u-button
        variant="link"
        to="/privacy-policy"
        class="p-0"
      >
        Privacy Policy
      </u-button>
      and make sure you agree to this applications
      <u-button
        variant="link"
        to="/terms"
        class="p-0"
      >
        terms
      </u-button> before proceeding.
    </p>
    <br>
    <u-button
      data-testid="agree-to-cookies"
      class="float-right"
      @click.stop.prevent="onAgree"
    >
      I agree
    </u-button>
  </div>
</template>

<script lang="ts" setup>
const cookiesAgreement = useCookie('the-show-portal-cookies-agreement')
const showAgree = ref(false)

function onAgree() {
  cookiesAgreement.value = 'true'
  showAgree.value = false
}

onMounted(() => {
  if (!cookiesAgreement.value) {
    showAgree.value = true
  }
})
</script>
