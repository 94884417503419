export const useData = defineStore('data', () => {
  const data = {
    admins: reactive(new Map<string, PortalAdmin>()),
    events: reactive(new Map<string, PortalEvent>()),
    events_entry_count: reactive(
      new Map<
        /**
         * The event id.
         */
        string,
        {
          showTotals: Record<
            /**
             * The show id.
             */
            string,
            number
          >
          specieTotals: Record<
            /**
             * The show id.
             */
            string,
            number
          >
          showmanshipTotals: Record<
            /**
             * The show id.
             */
            string,
            number
          >
          entryFeeTotals: Record<
            /**
             * The show id.
             */
            string,
            number
          >
          extraFeeTotals: Record<
            /**
             * The show id.
             */
            string,
            number
          >
          extraTotals: Record<
            /**
             * The show id.
             */
            string,
            number
          >
        }
      >(),
    ),
    exhibitors: reactive(new Map<string, PortalExhibitor>()),
    extras: reactive(new Map<string, PortalExtra>()),
    feed: reactive(new Map<string, PortalFeed>()),
    payments: reactive(new Map<string, PortalPayment>()),
    payments_exhibitors: reactive(new Map<string, PortalPaymentExhibitor>()),
    payments_show_objects: reactive(new Map<string, PortalPaymentShowObject>()),
    payments_extras: reactive(new Map<string, PortalPaymentExtra>()),
    scope_requests: reactive(new Map<string, unknown>()),
    scopes: reactive(
      new Map<
        /**
         * The resource id.
         */
        string,
        PortalScope
      >(),
    ),
    show_objects: reactive(new Map<string, PortalShowObject>()),
    shows: reactive(new Map<string, PortalShow>()),
    subusers: reactive(new Map<string, PortalSubuser>()),
  }

  /**
   * Cache many values for a given data key.
   *
   * @param key - The data key.
   * @param values - The values to cache.
   */
  function cacheMany<T = unknown>(
    key: keyof typeof data,
    values: T[] | null | undefined,
  ) {
    if (!data[key] || !Array.isArray(values)) {
      return
    }

    if (key === 'scopes') {
      for (const scope of values as PortalScope[]) {
        data.scopes.set(scope.resource_id, scope)
      }
    }
    else {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      for (const record of values as any[]) {
        data[key].set(record.id as string, record)
      }
    }
  }

  function clearAll() {
    for (const key in data) {
      if (key === 'events_entry_count' || key === 'events' || key === 'shows') {
        // Keep data that is public
        continue
      }

      data[key as keyof typeof data].clear()
    }
  }

  return {
    ...data,
    cacheMany,
    clearAll,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useData, import.meta.hot))
}
