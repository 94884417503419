import short from 'short-uuid'

/**
 * Transform a string to and from a short UUID.
 *
 * @param shortOrUUID - A short UUID or a v4 UUID.
 * @returns A short UUID or a v4 UUID.
 */
export default function useShortId(shortOrUUID: string | string[] | undefined) {
  if (!shortOrUUID) {
    return ''
  }

  if (shortOrUUID.length === 36) {
    // UUID
    return short().fromUUID(String(shortOrUUID))
  }
  else {
    // Short
    return short().toUUID(String(shortOrUUID))
  }
}
