export default defineAppConfig({
  ui: {
    safelistColors: ['neutral', 'tspblue', 'tsppink', 'tspbrown'],
    primary: 'tspblue',
    gray: 'neutral',
    icons: {
      dark: 'i-ph-moon',
      light: 'i-ph-sun',
      system: 'i-ph-monitor',
      search: 'i-ph-magnifying-glass',
      external: 'i-ph-arrow-up-right',
      chevron: 'i-ph-caret-down',
      hash: 'i-ph-hash',
      menu: 'i-ph-list',
      close: 'i-ph-x',
      check: 'i-ph-check-circle',
    },
    button: {
      default: {
        loadingIcon: 'i-ph-circle-notch',
        color: 'tspblue',
        size: 'lg',
        variant: 'solid',
      },
      variant: {
        solid:
          'text-white dark:text-white active:bg-{color}-600 dark:active:bg-{color}-600 '
          + 'whitespace-nowrap',
        link: 'text-base font-bold dark:text-tsppink-400',
      },
      rounded: 'rounded',
    },
    input: {
      default: {
        loadingIcon: 'i-ph-circle-notch',
      },
    },
    select: {
      default: {
        loadingIcon: 'i-ph-circle-notch',
        trailingIcon: 'i-ph-caret-down',
      },
    },
    selectMenu: {
      default: {
        selectedIcon: 'i-ph-check',
      },
    },
    notifications: {
      position: 'top-0 bottom-auto right-auto left-[50%] translate-x-[-50%]',
    },
    notification: {
      default: {
        closeButton: {
          icon: 'i-ph-x',
        },
        timeout: 4000,
      },
      transition: {
        enterFromClass: 'translate-y-0 opacity-0 sm:translate-y-0 sm:translate-x-0',
        enterToClass: 'translate-y-0 opacity-100 sm:translate-x-0',
      },
    },
    commandPalette: {
      default: {
        icon: 'i-ph-magnifying-glass',
        loadingIcon: 'i-ph-circle-notch',
        selectedIcon: 'i-ph-check',
        emptyState: {
          icon: 'i-ph-magnifying-glass',
        },
      },
    },
    table: {
      default: {
        sortAscIcon: 'i-ph-sort-ascending',
        sortDescIcon: 'i-ph-sort-descending',
        sortButton: {
          icon: 'i-ph-arrows-clockwise',
        },
        loadingState: {
          icon: 'i-ph-circle-notch',
        },
        emptyState: {
          icon: 'i-ph-database',
        },
      },
    },
    pagination: {
      default: {
        firstButton: {
          icon: 'i-ph-caret-left',
        },
        prevButton: {
          icon: 'i-ph-arrow-left',
        },
        nextButton: {
          icon: 'i-ph-arrow-right',
        },
        lastButton: {
          icon: 'i-ph-caret-right',
        },
      },
    },
    accordion: {
      default: {
        openIcon: 'i-ph-caret-down',
      },
      item: {
        color: '',
      },
    },
    breadcrumb: {
      active: 'text-primary-500 dark:text-tsppink-400',
      default: {
        divider: '/',
      },
    },
    modal: {
      container: 'items-center',
    },
    dashboard: {
      navbar: {
        wrapper: 'flex-shrink-0 flex items-center pl-0 pr-4 gap-x-2 min-w-0 border-none',
        title:
          'flex items-center gap-1.5 min-h-[67px] invisible md:visible pl-4 font-bold '
          + 'whitespace-nowrap truncate',
      },
      toolbar: {
        wrapper: 'min-h-0 p-0',
      },
      panel: {
        content: {
          wrapper: 'p-4 pb-16 flex-1 flex flex-col overflow-y-auto',
        },
      },
    },
    pricing: {
      card: {
        wrapper: 'relative flex flex-col self-stretch w-full',
        highlight: 'ring-2 ring-primary dark:ring-tsppink-300',
        scale: 'lg:scale-[1.1] lg:z-10',
        rounded: 'rounded-xl',
        body: {
          base: 'flex-1 gap-6 lg:gap-x-8 xl:gap-x-10 flex flex-col',
          padding: 'p-6 lg:p-8 xl:p-10',
        },
        inner: 'flex items-center gap-3',
        title:
          'text-2xl text-gray-900 dark:text-white sm:text-3xl font-semibold truncate',
        description: 'text-sm sm:text-base text-gray-500 dark:text-gray-400 mt-2',
        amount: {
          base: 'flex flex-row items-baseline gap-x-1',
          discount:
            'text-gray-500 dark:text-gray-400 line-through text-xl sm:text-2xl '
            + 'font-medium',
          price: 'text-gray-900 dark:text-white text-2xl sm:text-4xl font-semibold',
          cycle: 'text-gray-500 dark:text-gray-400 text-sm/6 font-medium truncate',
        },
        features: {
          vertical: 'space-y-3 text-sm',
          horizontal: 'grid lg:grid-cols-2 text-sm gap-3',
          item: {
            base: 'flex items-center gap-x-3 min-w-0',
            label: 'text-gray-600 dark:text-gray-400 truncate',
            icon: {
              base: 'w-5 h-5 flex-shrink-0 text-primary dark:text-tsppink-400',
              name: 'i-ph-check-circle-fill',
            },
          },
        },
        divider: 'my-6 lg:my-8',
        left: '',
        right: '',
      },
    },
  },
})
