import type { SchemaUiContentful } from '@tarcltd/form-vue'

export default async function useShowEntryLimits(showId: string, subuserId: string) {
  const supabase = useSupabaseClient<Supabase>()
  const data = useData()
  const auth = useAuth()
  const state = {
    is_entries_per_specie_full: false,
    is_entries_per_specie_per_exhibitor_full: false,
    is_entries_full: false,
    is_entries_per_exhibitor_full: false,
    total_entries: 0,
    total_subuser_entries: 0,
    number_entries_requested: 0,
  }

  let existingShow = data.shows.get(showId)

  if (!existingShow) {
    const { data: show } = await supabase
      .from('shows')
      .select()
      .eq('id', showId)
      .maybeSingle<PortalShow>()

    if (show) {
      data.shows.set(showId, show)

      existingShow = show
    }
  }

  if (!existingShow) {
    return state
  }

  let existingEvent = data.events.get(existingShow.event_id)

  if (!existingEvent) {
    const { data: event } = await supabase
      .from('events')
      .select()
      .eq('id', existingShow.event_id)
      .maybeSingle<PortalEvent>()

    if (event) {
      data.events.set(event.id, event)

      existingEvent = event
    }
  }

  if (!existingEvent) {
    return state
  }

  const { data: showObjects, count: showObjectsCount } = await supabase
    .from('show_objects')
    .select('subuser_id', { count: 'exact' })
    .eq('show_id', showId)

  if (typeof showObjectsCount === 'number') {
    state.total_entries = showObjectsCount

    state.total_subuser_entries = showObjects.filter(
      (showObject) => showObject.subuser_id === subuserId,
    ).length
  }

  state.number_entries_requested = auth.userCart.filter(
    (i) =>
      i.type === 'show_object'
      && i.show_id === showId
      && i.subuser_id === subuserId,
  ).length

  state.is_entries_per_specie_full = Boolean(
    existingEvent.metadata.max_entries_per_specie
    && existingEvent.metadata.max_entries_per_specie[
      (existingShow.manifest.properties._title as SchemaUiContentful).content as string
    ] <= state.total_entries,
  )
  state.is_entries_per_specie_per_exhibitor_full = Boolean(
    existingEvent.metadata.max_entries_per_specie_per_exhibitor
    && existingEvent.metadata.max_entries_per_specie_per_exhibitor[
      (existingShow.manifest.properties._title as SchemaUiContentful).content as string
    ] <= state.total_subuser_entries,
  )
  state.is_entries_full = existingShow.max_entries <= state.total_entries
  state.is_entries_per_exhibitor_full = (
    existingShow.max_entries_per_exhibitor
    < state.total_subuser_entries + state.number_entries_requested)
  return state
}
