import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp
    const router = useRouter()
    const auth = useAuth()
    const config = useRuntimeConfig()

    if (!config.public.sentry.dsn) {
      console.warn('Sentry DSN not set, skipping Sentry initialization')
      return
    }

    // https://github.com/getsentry/sentry-javascript/issues/12490
    /* eslint-disable @typescript-eslint/no-explicit-any */
    (globalThis as any)._sentryEsmLoaderHookRegistered = true

    Sentry.init({
      app: vueApp,
      dsn: config.public.sentry.dsn,
      integrations: [
        Sentry.captureConsoleIntegration({ levels: ['error'] }),
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
        }),
        Sentry.feedbackIntegration({
          colorScheme: 'dark',
        }),
      ],
      attachStacktrace: true,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: config.public.sentry.tracesSampleRate,
      // This sets the sample rate. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: config.public.sentry.replaysSessionSampleRate,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: config.public.sentry.replaysOnErrorSampleRate,
    })

    watch(() => auth.user, (value) => {
      if (value) {
        Sentry.setUser({
          id: value.id,
          email: value.email,
          username: `${value.name_first} ${value.name_last}`,
          ip_address: '{{auto}}',
        })
      }
      else {
        Sentry.setUser(null)
      }
    }, { immediate: true, deep: true })
  },
})
