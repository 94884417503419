import dayjs from 'dayjs/esm'
import advancedFormat from 'dayjs/esm/plugin/advancedFormat'
import isBetweenPlugin from 'dayjs/esm/plugin/isBetween'
import timezonePlugin from 'dayjs/esm/plugin/timezone'
import utcPlugin from 'dayjs/esm/plugin/utc'

dayjs.extend(advancedFormat)
dayjs.extend(isBetweenPlugin)
dayjs.extend(timezonePlugin)
dayjs.extend(utcPlugin)

const useTime = dayjs
export default useTime
