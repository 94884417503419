<template>
  <u-modal v-model="internalValue">
    <div class="grid gap-4 p-4">
      <p>
        For immediate event assistance, please contact your event organizer. Their contact
        information may be displayed on their event. If you are having trouble contacting
        your event organizer please let us know, however we are typically unable to
        provide immediate response for this type of request.
      </p>
      <p>
        Inquiries regarding technical issues should be directed to
        <!-- eslint-disable -->
        <u-button 
          variant="link" 
          class="p-0" 
          to="mailto:The Show Portal Support <support@theshowportal.com>" 
          target="__black" 
          rel="noopener noreferrer"
          data-testid="contact-link">
            support@theshowportal.com
        </u-button>. We typically respond within 24 to 48 hours.
        <!-- eslint-enable -->
      </p>
      <u-button
        color="white"
        class="w-fit"
        @click="internalValue = false"
      >
        Close
      </u-button>
    </div>
  </u-modal>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean
}>()
const emit = defineEmits(['update:modelValue'])
const internalValue = computed({
  get: () => props.modelValue,
  set: (value: boolean) => emit('update:modelValue', value),
})
</script>
